import { setterAmount, setterDate } from '@/plugins/utils';

export interface InvoicesListModelImpl {
  _id: string;
  invoice_num: string;
  invoice_date: Date;
  invoice_client_country: string;
  invoice_price: number;
  invoice_price_currency: string;
  payment_registry_id: string;
}

export class InvoicesListModelDTO implements InvoicesListModelImpl {
  _id = '';
  invoice_num = '';
  invoice_date = new Date();
  invoice_client_country = '';
  invoice_price = 0;
  invoice_price_currency = '';
  payment_registry_id = '';
}

export default class InvoicesListModel extends InvoicesListModelDTO {
  constructor(dto: InvoicesListModelDTO) {
    super();
    Object.assign(this, dto);
  }

  getInvoicePrice = () => this.generatePriceFormat(this.invoice_price.toFixed(2));
  getInvoiceDate = () => setterDate(this.invoice_date);

  private generatePriceFormat = (amount: string) => amount.replace(/\d(?=(\d{3})+\.)/g, '$&,');
}
