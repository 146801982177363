import API from '../repository';
import InvoicesListModel, { InvoicesListModelDTO } from '@/models/InvoicesList.model';

const RESOURCE = '/invoices';

export default {
  async findInvoices({
    country,
    monthDate,
  }: {
    country: string;
    monthDate: string;
  }): Promise<{ invoicesList: InvoicesListModel[] }> {
    const { invoices } = await API.post(`${RESOURCE}`, {
      country,
      monthDate,
    });
    const invoicesListModels = invoices.map(
      (invoicesListDto: InvoicesListModelDTO) => new InvoicesListModel(invoicesListDto),
    );
    return {
      invoicesList: invoicesListModels,
    };
  },
  async downloadAllInvoices({ country, monthDate }: { country: string; monthDate: string }) {
    API.post(
      `${RESOURCE}/download`,
      {
        country,
        monthDate,
      },
      { responseType: 'blob' },
    ).then(data => {
      const downloadUrl = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `${monthDate}.zip`); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  },

  async downloadInvoice(invoice_id: string, nameFile: string) {
    API.get(`${RESOURCE}/${invoice_id}/download`, { responseType: 'blob' }).then(data => {
      const downloadUrl = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `${nameFile}.pdf`); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  },
};
